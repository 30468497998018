<template>
    <div class="clearfix"
         :class="cellPosition">
        <i class="pe-7s-close close-information" @click.stop="closeInformationBox" />

        <div class="row">
            <div class="col-lg-4">
                <strong>{{$t('integrationCheck.cellInformation.customer')}}</strong>
            </div>
            <div class="col-lg-8">
                {{customer.name}}
            </div>
        </div>
        <div class="row" style="margin-top: 30px;">
            <div class="col-lg-4">
                <strong>{{$t('integrationCheck.cellInformation.integration')}}</strong>
            </div>
            <div class="col-lg-8">
                <router-link
                        :to="{ name: 'backend.customers.view.integrations', params: {id: customer.id} }"
                        target="_blank"
                        v-if="product.quickIntegrate">
                    <span>{{product.title}}</span>
                </router-link>

                <router-link
                        :to="{ name: 'backend.customers.view.keywords', params: {id: customer.id} }"
                        target="_blank"
                        v-else>
                    <span>{{product.title}}</span>
                </router-link>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <!-- Integrated -->
            <div class="col-lg-4">
                <strong>{{$t('integrationCheck.cellInformation.activeIntegration')}}</strong>
            </div>

            <div class="col-lg-8">
                <template v-if="status === 'active'">
                    {{$t('integrationCheck.yes')}}
                </template>
                <template v-else>
                    {{$t('integrationCheck.no')}}
                </template>
            </div>
        </div>

        <div class="row">
            <!-- Healthy -->
            <template v-if="healthy">
                <div class="col-lg-12">
                    <strong>{{$t('integrationCheck.cellInformation.health')}}</strong>
                </div>
                <div class="col-lg-12">
                    <span class="health-tip" v-html="healthProblems.lostConnection" />
                </div>
            </template>

            <div class="col-lg-12">
                    <hr>
                <strong>{{$t('integrationCheck.cellInformation.note')}}</strong>
            </div>
            <div class="col-lg-12" style="margin-bottom: 20px">
                <form>
                    <textarea id="note-content" type="text" :placeholder="$t('integrationCheck.cellInformation.notePlaceholder')" maxlength="100" v-model="draft" />
                    <button @click.prevent="createNote" v-if="draft.length">{{$t('integrationCheck.cellInformation.addNote')}}</button>
                </form>

                <hr style="margin: 0">
            </div>
            <template v-if="hasLoaded">
                <div class="col-lg-12 note-container" v-for="note in sortedNotes">
                    <span class="note-text" :class="{ owner: note.user && note.user.id === identity.id }">{{note.note}}</span><br>
                    <small>{{note.user.name}}</small> -
                    <small class="note-date"><strong>{{note.created.date | moment('LLL')}}</strong> - </small>
                    <small class="button__delete-note" @click="deleteNote(note)">slet</small>
                </div>
            </template>
            <div class="col-lg-12" style="height: 60px;" v-else>
                <logo-loader />
            </div>

            <div class="col-lg-12"
                 :style="sortedNotes.length ?
                    {
                        marginTop: '10px',
                        paddingTop: '20px'
                    } : null" >
                <component v-if="product.quickIntegrate"
                           :is="product.quickIntegrate"
                           :customerId="customer.id"
                           @integrationStateChange="integrationConnectionChange" />
            </div>
        </div>

        <hr style="margin: 0">
        <div class="row" style="margin-top: 20px;">
            <ghost-identity-button :customer="customer"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../scss/IntegrationBox";
</style>

<script>
    import LogoLoader from '@/app/shared/components/LogoLoader'

    import GoogleAnalytics from '@/app/google-ads/components/cached-accounts/GoogleAnalytics'
    import GoogleSearchConsole from '@/app/google-ads/components/cached-accounts/GoogleSearchConsole'
    import GoogleBusinessProfile from '@/app/google-ads/components/cached-accounts/GoogleBusinessProfile'
    import GoogleAds from '@/app/google-ads/components/cached-accounts/GoogleAds'

    import FacebookAds from '@/app/meta/components/cached-accounts/FacebookAds'
    import FacebookPages from '@/app/meta/components/cached-accounts/FacebookPages'

    const ServiceNoteService = require('@/services/integrations/ServiceNoteService')

    const moment = require('moment')

    import { mapGetters } from 'vuex'
    import GhostIdentityButton from '@/app/users/components/GhostIdentityButton'

    export default {
        props: {
            customer: Object,
            columns: Number,
            healthy: Boolean,
            index: Number,
            notes: {
                type: Array,
                required: true
            },
            product: {
                type: Object,
                required: true
            },
            rowInformation: Object,
            status: String,
            service: Object,
        },

        data() {
            return {
                draft: '',
                serviceId: '',
                hasLoaded: true,
                healthProblems: {
                    lostConnection: $t('integrationCheck.cellInformation.healthExplainers.lostIntegration')
                }
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

            cellPosition() {
                let posX = this.index > (this.columns / 2)
                    ? 'hover-right'
                    : 'hover-left'

                let posY = this.rowInformation.currentRow > (this.rowInformation.rows / 2)
                    ? 'hover-bottom'
                    : 'hover-top'

                return [posX, posY]
            },

            sortedNotes() {
                return this.notes.sort((a,b) => b.created - a.created)
            }
        },

        methods: {
            createNote() {
                if (!this.draft.length) {
                    return
                }

                this.hasLoaded = false

                const data = {
                    customer: this.customer.id,
                    service: this.service.id,
                    note: this.draft
                }

                ServiceNoteService.create(data, response => {
                    this.draft = ''
                    this.hasLoaded = true

                    const serviceNote = response.data
                    serviceNote.user = this.identity

                    this.$emit('noteCreated', {
                        customer: this.customer,
                        service: this.service,
                        serviceNote: serviceNote
                    })
                }, error => {})
            },

            deleteNote(serviceNote) {
                this.hasLoaded = false

                ServiceNoteService.remove(serviceNote.id, response => {
                    this.$emit('noteRemoved', {
                        customer: this.customer,
                        service: this.service,
                        serviceNote: serviceNote
                    })

                    this.hasLoaded = true
                })
            },

            closeInformationBox() {
                this.$emit('closeBox')
            },

            integrationConnectionChange(payload) {
                this.$emit('integrationStateChange', payload)
            }
        },

        components: {
            GhostIdentityButton,
            LogoLoader,
            GoogleAnalytics,
            GoogleSearchConsole,
            GoogleBusinessProfile,
            GoogleAds,
            FacebookAds,
            FacebookPages
        }
    }
</script>
