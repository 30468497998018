<template>
    <div class="integration-check-page">
        <card>
            <div class="row">
                <div class="col-lg-12 filter-container">
                    <search class="search" :options="filters" @filters-updated="updateFilters" />
                </div>
            </div>

            <div class="row" style="padding: 0 15px;">
                <div class="col-lg-12 legend-container">
                    <small><span class="legend legend-color--positive"></span> {{$t('integrationCheck.legends.isIntegrated')}}</small>
                    <small><span class="legend legend-color--negative"></span> {{$t('integrationCheck.legends.shouldBeIntegrated')}}</small>
                    <small><span class="legend legend-color--neutral"></span> {{$t('integrationCheck.legends.idleIntegration')}}</small>
                    <small><img src="/images/fa-heart-broken.svg" /> {{$t('integrationCheck.legends.badHealth')}}</small>
                    <small><img src="/images/fa-comment.svg" /> {{$t('integrationCheck.legends.hasComments')}}</small>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <table style="width: 100%;">
                        <thead>
                            <tr>
                                <td class="thead-title">
                                    <h6>{{$t('integrationCheck.customers')}}</h6>
                                </td>
                                <td class="thead-integrations">
                                    <table width="100%">
                                        <tr>
                                            <td class="thead-product"
                                                v-for="product in visibleTableProducts"
                                                :width="columnWidth">
                                                <img class="integration-logo" :src="product.url" /><br>
                                                <small>{{product.title}}</small>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="customer in customers">
                            <td class="company">
                                <small class="company__name ellipsis">
                                    {{customer.name}}
                                    <span class="label label--no-user"
                                          v-if="!customer.users || !customer.users.length">{{$t('integrationCheck.noUserCreated')}}</span><br>
                                    <a :href="'http://www.' + customer.domain" rel="noopener noreferrer" target="_blank">
                                        <span class="company__domain">{{customer.domain}}</span>
                                    </a>
                                </small>
                            </td>
                            <td width="80%">
                                <table width="100%">
                                    <tr>
                                        <td class="integration"
                                            v-for="(product, index) in visibleTableProducts"
                                            :class="integrationStatus(product, customer)"
                                            :width="columnWidth"
                                            :key="index"
                                            @click="activeCell = `${customer.id}-${index}`">

                                            <img src="/images/fa-heart-broken.svg"
                                                 v-if="!integrationIsHealthy(customer, product.title)" />

                                            <img src="/images/fa-comment.svg"
                                                 v-if="hasNote(customer, product)" />

                                            <template v-if="product.title === 'Seo'">
                                                <i class="keywords-total">{{customer.keywords.length}}</i>
                                            </template>

                                            <integration-box v-if="activeCell === `${customer.id}-${index}`"
                                                             class="integration-status"
                                                             :customer="customer"
                                                             :rowInformation="getRowInformation(customer)"
                                                             :columns="visibleTableProducts.length"
                                                             :health="integrationIsHealthy(customer, product.title)"
                                                             :index="index"
                                                             :notes="getNotes(customer, product)"
                                                             :product="product"
                                                             :status="integrationStatus(product, customer)"
                                                             :service="services[product.title]"
                                                             @closeBox="closeBox"
                                                             @integrationStateChange="integrationConnectionChange"
                                                             @noteCreated="noteCreatedListener"
                                                             @noteRemoved="noteRemovedListener"
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        </tbody>

                        <tbody v-if="!customers.length && !searchingForCustomers">
                        <!-- Finished loading, no results -->
                        <tr>
                            <td class="company" style="height: 60px !important; padding-left: 20px;" colspan="100%">
                                <small class="company__name ellipsis">
                                    {{$t('integrationCheck.emptySearchResult')}}
                                </small>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <infinite-loading
                            v-if="hasResults"
                            @infinite="loadNextPage($event)"
                            :distance="150"
                            force-use-infinite-wrapper="true">
                        <span slot="no-results"></span>
                        <span slot="no-more"></span>
                    </infinite-loading>
                </div>
            </div>
        </card>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_mixins.scss';

	.integration-check-page{
		.card {
			overflow: visible !important;
		}
	}

    .ellipsis {
        @include ellipsis;
    }

    .legend-container {
        padding: 15px;
        small {
            margin-right: 15px;
            img {
                width: 15px;
                opacity: 0.4;
                margin-right: 5px;
            }
        }

        .legend {
            display:inline-block;
            width:10px;
            height:10px;
            border-radius:50%;
        }

        .legend-color--positive {
            background: rgba(117, 250, 87, 0.2);
        }

        .legend-color--negative {
            background: rgba(206, 77, 77, 0.6);
        }

        .legend-color--neutral {
            background: #ffffff;
            border: 1px solid #d4d4d4;
        }
    }

    .label {
        font-size: 8px;
        display: inline-block;
        &.label--no-user {
            background: #2e6da4;
            color: #ffffff;
            padding: 3px 5px;
            border-radius: 2px;
            margin-left: 5px;
        }
    }

    .filter-container {
        margin-bottom: 20px;
        .filter-button {
            padding: 10px 20px;
            display: inline-block;
            border-radius: 4px;
            margin-right: 10px;
        }
    }

    .integration-logo {
        width: 20px;
    }

    .keywords-total {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px !important;
        font-weight: 700;
        display: inline-block;
        position: absolute;
        bottom: 5px;
        left: 10px;
    }

    * {
        border-collapse: collapse !important;
    }

    table {
        thead {
            background: #f9f9f9;
            tr {
                height: 0;
                td {
                    position: sticky;
                    top: -1px;
                    z-index: 999999;
                    background: #f9f9f9;
                    padding: 10px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    table tr td {
                        border: none !important;
                    }

                    &.thead-title {
                        width: 20%;
                        padding-left: 20px;
                    }

                    &.thead-integrations {
                        width: 80%;
                    }

                    &.thead-product {
                        padding-left: 10px;
                        text-align: center;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    height: 100%;
                    &.company {
                        padding-left: 20px;
                        width: 20%;
                        .company__name {
                            font-weight: 500;
                        }

                        .company__domain {
                            font-size: 10px;
                            color: #888;
                            margin: 0;
                        }
                    }

                    &.integration {
                        position: relative;
                        img {
                            display: inline-block;
                            width: 20px;
                            opacity: 0.5;
                        }
                    }

                    a {
                        color: #888888;
                        &:hover {
                            color: #333333;
                        }
                    }

                    table {
                        tr {
                            td {
                                height: 50px;
                                outline: 1px solid #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        .legend-container {
            small {
                display: block;
                margin-bottom: 5px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        table {
            thead {
                tr {
                    height: 200px;
                    td {
                        &.thead-title {
                            display: none !important;
                        }

                        &.thead-product {
                            padding: 0;
                            text-align: left;
                        }

                        &.thead-integrations {
                            width: 100%;
                            table tr td {
                                position: relative;
                                img {
                                    position: absolute;
                                    top: 10px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                                small {
                                    display: inline-block;
                                    transform: rotate(90deg);
                                    text-align: left;
                                    width: 100px;
                                }
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        &.company {
                            display: none;
                        }
                    }
                }
            }
        }
    }
</style>

<script>
    import IntegrationBox from '@/app/integration-check/components/IntegrationBox'
    import Search from '@/app/shared/global/search/Search'
    import Card from '@/app/shared/components/Card'

    import { mapGetters } from 'vuex'

    import InfiniteLoading from 'vue-infinite-loading'

    import hasIntegration from '@/mixins/integrations/hasIntegration'

    const CustomerService = require('@/services/customers/CustomerService')
    const OrganizationService = require('@/services/customers/OrganizationService')
    const ServiceService = require('@/services/integrations/ServiceService')
    const FilterService = require('@/services/filters/FilterService')
    const ServiceNoteService = require('@/services/integrations/ServiceNoteService')

    import EmployeesFilter from '@/services/filters/custom/Employees'

    export default {
        mixins: [hasIntegration],

        data() {
            return {
                searchingForCustomers: false,
                operationId: null,
                customers: [],
                cellHeight: null,
                serviceNotes: {},
                activeCell: null,
                customerTotalKeywords: {},
                services: {},
                visibleTableProducts: [
                    {
                        title: 'Google analytics',
                        id: 'f55e7c6d-6928-4ad2-8951-80f371d4847f',
                        quickIntegrate: 'google-analytics',
                        url: '/images/onboarding/google-analytics.svg'
                    },
                    {
                        title: 'Seo',
                        id: 'cc3b95a8-462f-40fb-b148-7802098baf9f',
                        quickIntegrate: null,
                        url: '/images/onboarding/seo.svg'
                    },
                    {
                        title: 'Google Search Console',
                        id: 'd3f30dd7-1b78-4c0b-828d-09e440821550',
                        quickIntegrate: 'google-search-console',
                        url: '/images/onboarding/google-search-console.svg'
                    },
                    {
                        title: 'Google Business Profile',
                        id: '2c9c2681-5ace-4526-854c-5f291b045b80',
                        quickIntegrate: 'google-business-profile',
                        url: '/images/onboarding/google-business.svg'
                    },
                    {
                        title: 'Google Ads',
                        id: '57152d61-3919-4540-9905-765bae200d47',
                        quickIntegrate: 'google-ads',
                        url: '/images/onboarding/google-adwords.svg'
                    },
                    {
                        title: 'Facebook ads',
                        id: '70d800f7-131e-43e7-8ca3-9f9ad2eaf7a7',
                        quickIntegrate: 'facebook-ads',
                        url: '/images/onboarding/facebook-ads.svg'
                    },
                    {
                        title: 'Facebook pages',
                        id: '94421467-7a40-41db-9e98-d8eec580dc31',
                        quickIntegrate: 'facebook-pages',
                        url: '/images/onboarding/facebook-pages.svg'
                    }
                ],
                numberOfFilters: 3,
                limit: 15,
                total: 0,
                page: 0,
                filters: {
                    query: null,
                    filters: [
						{
							key: 'customerStatus',
							empty: $t('customers.filters.customerStatus.title'),
							prefix: $t('customers.filters.customerStatus.title'),
							value: null,
							options: [
								{
									label: $t('customers.filters.customerStatus.all'),
									value: 'all'
								},
								{
									label: $t('customers.filters.customerStatus.client'),
									value: 'client'
								},
								{
									label: $t('customers.filters.customerStatus.lead'),
									value: 'lead'
								}
							]
						}
					]
                }
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

            columnWidth() {
                return (100 / this.visibleTableProducts.length) + '%'
            },

            hasResults() {
                return this.customers.length > 0 && this.page * this.limit < this.total
            }
        },

        mounted() {
            this.loadFilters()
            this.serviceLoader()
        },

        methods: {
            loadNextPage($state) {
                this.loadCustomers($state)
            },

            noteCreatedListener(payload) {
                const customer = payload.customer
                const service = payload.service
                const serviceNote = payload.serviceNote

                this.addServiceNote(customer, service, serviceNote)
            },

            noteRemovedListener(payload) {
                const customer = payload.customer
                const service = payload.service
                const serviceNote = payload.serviceNote

                this.removeServiceNote(customer, service, serviceNote)
            },

            loadCustomers($state) {
                this.searchingForCustomers = true
                this.page++

                OrganizationService.findAll({
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'freemium',
                                    operator: 'eq',
                                    value: false
                                }
                            ]
                        }
                    ]
                }, response => {
                    const organizations = response.data

                    const options = {
                        includes: ['organization'],

                        limit: this.limit,
                        page: this.page,

                        sort: [{
                            key: 'created',
                            direction: 'DESC'
                        }],

                        filter_groups: [
                            ...FilterService.toRequest(this.filters, [
                                'name',
                                'domain'
                            ]),

                            {
                                filters: [
                                    {
                                        key: 'organization.id',
                                        operator: 'in',
                                        value: organizations.rows.map(organization => organization.id)
                                    },
                                    {
                                        key: 'active',
                                        operator: 'eq',
                                        value: 1
                                    }
                                ]
                            }
                        ]
                    }

                    CustomerService.findAll(options, response => {
                        const data = response.data
                        const rows = data.rows

                        if (this.total === 0) {
                            this.total = data.total
                        }

                        if (!rows.length) {
                            this.searchingForCustomers = false
                            return
                        }

                        const options = {
                            sort: [{
                                key: 'created',
                                direction: 'DESC'
                            }],

                            includes: [
                                'industry',
                                'clientManager',
                                'integrations',
                                'integrations.service',
                                'users',
                                'keywords',
                                'organization'
                            ],

                            filter_groups: [
                                {
                                    filters: [
                                        {
                                            key: 'id',
                                            operator: 'in',
                                            value: rows.map(customer => customer.id)
                                        }
                                    ]
                                }
                            ]
                        }

                        CustomerService.findAll(options, response => {
                            const data = response.data
                            this.searchingForCustomers = false

                            if (!data.rows.length) {
                                $state.complete()
                                $state.loaded()
                                return
                            }

                            const customers = data.rows

                            this.customers.push(...customers)
                            this.loadNotes(customers)

                            if (this.customers.length >= this.total) {
                                $state.complete()
                                return
                            }

                            $state.loaded()
                        })
                    })
                })
            },

            loadNotes(customers) {
                const operationId = '_' + Math.random().toString(36).substr(2, 9)
                this.operationId = operationId

                customers.forEach(customer => {
                    const options = {
                        sort: [{
                            key: 'created',
                            direction: 'DESC'
                        }],

                        includes:  [
                            'service',
                            'user'
                        ],

                        filter_groups: [
                            {
                                filters: [
                                    {
                                        key: 'customer',
                                        operator: 'eq',
                                        value: customer.id
                                    }
                                ]
                            }
                        ]
                    }

                    ServiceNoteService.findAll(options, response => {
                        if (operationId !== this.operationId) {
                            return
                        }

                        const data = response.data
                        const rows = data.rows

                        rows.forEach(serviceNote => {
                            const service = serviceNote.service

                            this.addServiceNote(customer, service, serviceNote)
                        })
                    })
                })
            },

            addServiceNote(customer, service, serviceNote) {
                const serviceNotes = this.serviceNotes

                if (serviceNotes[customer.id] === undefined) {
                    this.$set(this.serviceNotes, customer.id, {})
                }

                if (serviceNotes[customer.id][service.id] === undefined) {
                    this.$set(this.serviceNotes[customer.id], service.id, [])
                }

                serviceNotes[customer.id][service.id].push(serviceNote)
            },

            removeServiceNote(customer, service, serviceNoteToRemove) {
                const serviceNotes = this.serviceNotes

                if (serviceNotes[customer.id] === undefined ||
                    serviceNotes[customer.id][service.id] === undefined
                ) {
                    return
                }

                this.$set(
                    serviceNotes[customer.id],
                    service.id,
                    serviceNotes[customer.id][service.id].filter(
                        serviceNote => serviceNote.id !== serviceNoteToRemove.id
                    )
                )
            },

            hasService(customer, service) {
                let hasService = false

                if (service === 'Seo' && customer.keywords.length >= 1) {
                    return true
                }

                customer.integrations.forEach(integration => {
                    if (service === integration.service.name) {
                        hasService = true
                    }
                })

                return hasService
            },

            integrationStatus(service, customer) {
                const serviceTitle = service.title
                const hasService = this.hasService(customer, serviceTitle)
                const healthy = this.integrationIsHealthy(customer, serviceTitle)

                return healthy && hasService ? 'active' : 'inactive'
            },

            integrationIsHealthy(customer, service) {
                const matchingIntegrations = customer.integrations.filter(integration => {
                    return service === integration.service.name
                })

                const healthyIntegrations = matchingIntegrations.filter(integration => {
                    return integration.healthy
                })

                return matchingIntegrations.length === healthyIntegrations.length
            },

            updateFilters(filters) {
                if (!filters || filters.filters.length !== this.numberOfFilters) {
                    return
                }

                this.$set(this.filters, 'query', filters.query)

                this.reset()
                this.loadCustomers()
            },

            reset() {
                this.customers = []
                this.serviceNotes = {}
                this.page = 0
                this.total = 0
            },

            serviceLoader() {
                ServiceService.findAll({}, response => {
                    const data = response.data
                    const rows = data.rows

                    rows.forEach(row => {
                        this.$set(this.services, row.name, {
                            name: row.name,
                            id: row.id
                        })
                    })
                })
            },

            loadFilters () {
                const identity = this.identity
                const id = identity.id
                let filtersAdded = 0

                EmployeesFilter((options, value) => {
                    this.filters.filters.push({
                        key: 'clientManager',
                        empty: $t('filters.empty'),
                        value: value,
                        prefix: $t('integrationCheck.filters.accountManager'),
                        options: options
                    })
                    filtersAdded++
                }, null)

                EmployeesFilter((options, value) => {
                    this.filters.filters.push({
                        key: 'withProductResponsible',
                        empty: $t('filters.empty'),
                        value: value,
                        prefix: $t('integrationCheck.filters.productOwner'),
                        options: options
                    })
                    filtersAdded++
                }, id)
            },

            hasNote(customer, service) {
                const serviceNotes = this.serviceNotes

                return serviceNotes[customer.id] !== undefined &&
                    serviceNotes[customer.id][service.id] !== undefined &&
                    serviceNotes[customer.id][service.id].length
            },

            getNotes(customer, service) {
                return this.hasNote(customer, service) ?
                    this.serviceNotes[customer.id][service.id] :
                    []
            },

            closeBox() {
                this.activeCell = null
            },

            integrationConnectionChange(payload) {
                const options = {
                    includes: [
                        'industry',
                        'clientManager',
                        'integrations',
                        'integrations.service',
                        'users',
                        'keywords',
                        'organization'
                    ]
                }

                CustomerService.find(payload, options, ({ data }) => {
                    this.customers.forEach((customer, index) => {
                        if (customer.id === payload) {
                            this.customers.splice(index, 1, data)
                        }
                    })
                })
            },

            getRowInformation(customer) {
                const customers = this.customers

                let index = 0

                for (let i = 0; i < customers.length; i++) {
                    if (customers[i].id === customer.id) {
                        index = i
                    }
                }

                return {
                    rows: customers.length,
                    currentRow: index
                }
            }
        },

        components: {
            Card,
            IntegrationBox,
            Search,
            InfiniteLoading,
        }
    }
</script>
